import styled from 'styled-components'

const HamburgerBox = styled.div`
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
`

export default HamburgerBox

import styled from 'styled-components'

import Typography from '~components/Typography'
import { media } from '~styles/mediaQuery'
import { calcFontSize } from '~styles/helpers'

const TextHeader = styled(Typography)`
  grid-area: header;
  color: ${({ theme }) => theme.palette.secondary.main};
  position: relative;
  z-index: 1;
  font-size: ${calcFontSize({ minFont: 32, maxFont: 84 })};
  ${media.mqMin`
    font-size: 32px;
  `};
  ${media.mqMax`
    font-size: 84px;
  `};
`

export default TextHeader

/**
 *
 * IconTaniaFerreira
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

import { icon } from '~styles/constants'

import Svg from './Svg'

const IconTaniaFerreira = ({ size, height, width, ...props }) => {
  const _height = size ? icon.size[size] : width || icon.size.medium
  const _width = size ? icon.size[size] : height || icon.size.medium
  return (
    <Svg width={_width} height={_height} {...props} viewBox="0 0 300 300">
      <path
        fill="#2D4262"
        stroke="none"
        d="M151.09 300L300 149.64 150.37 0 0 149.64 151.09 300"
      />
      <path
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M145.05 77.87v92.39l-9.9-9.9V87.77h-26.4l-9.9-9.9h92.4"
      />
      <path
        fill="none"
        d="M201.15 87.77H155v92.39l9.24-9.9v-42.9h21.56L155 87.77"
        strokeMiterlimit="10"
        strokeWidth="3"
      />
      <text
        stroke="none"
        transform="translate(66.04 209.12)"
        fontSize="21"
        fontFamily="Raleway-Medium, Raleway"
        letterSpacing="-.06em"
      >
        T
        <tspan x="11.68" y="0" letterSpacing=".01em">
          ANI
        </tspan>
        <tspan x="48.17" y="0" letterSpacing="-.02em">
          A
        </tspan>
        <tspan x="61.97" y="0" letterSpacing=".01em">
          {' '}
          FERREI
        </tspan>
        <tspan x="139.88" y="0" letterSpacing="0em">
          R
        </tspan>
        <tspan x="153.89" y="0" letterSpacing="-.02em">
          A
        </tspan>
      </text>
    </Svg>
  )
}

IconTaniaFerreira.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  height: PropTypes.number,
  width: PropTypes.number,
}

export default IconTaniaFerreira

import styled from 'styled-components'
import { Link } from 'gatsby'

import { media } from '~styles/mediaQuery'

const NavLogo = styled(Link)`
  grid-area: logo;
  display: flex;
  position: relative;
  z-index: 1;
  ${media.mq1`
    margin-bottom: ${(props) => props.theme.spacing[12]};
  `};
`

export default NavLogo

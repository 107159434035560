import styled from 'styled-components'

import { transitions } from '~styles/constants'

const Svg = styled.svg`
  stroke: ${(props) =>
    props.isActive
      ? props.theme.palette.secondary.main
      : props.theme.palette.common.white};
  fill: ${(props) =>
    props.isActive
      ? props.theme.palette.secondary.main
      : props.theme.palette.common.white};
  transition: stroke ${transitions.duration.standard}ms
      ${transitions.easing.easeInOut},
    fill ${transitions.duration.standard}ms ${transitions.easing.easeInOut};

  &:hover {
    stroke: ${(props) => props.theme.palette.secondary.main};
    fill: ${(props) => props.theme.palette.secondary.main};
  }
`

export default Svg

import { useState, useEffect } from 'react'

const getSize = () => ({
  innerHeight: typeof window !== 'undefined' ? window.innerHeight : 1024,
  innerWidth: typeof window !== 'undefined' ? window.innerWidth : 1024,
  outerHeight: typeof window !== 'undefined' ? window.outerHeight : 1024,
  outerWidth: typeof window !== 'undefined' ? window.outerWidth : 1024,
})

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize())

  const handleResize = () => {
    setWindowSize(getSize())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}

export default useWindowSize

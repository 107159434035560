import styled from 'styled-components'

import Typography from '~components/Typography'
import { fluidSpacingFunc } from '~styles/helpers'

const TextSubHeader = styled(Typography)`
  grid-area: subHeader;
  ${fluidSpacingFunc([{ property: 'padding', values: [0, 2] }])};
  color: ${({ theme }) => theme.palette.text.primary.contrastText};
  position: relative;
  z-index: 1;
`

export default TextSubHeader

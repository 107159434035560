import styled from 'styled-components'

const Wrapper = styled.main`
  display: grid;
  height: 100vh;
  text-align: center;
  grid-row-gap: ${({ theme }) => theme.spacing[16]};
  grid-template-rows:
    7fr repeat(2, minmax(min-content, 1fr)) 7fr repeat(
      2,
      minmax(min-content, 1fr)
    )
    3fr;
  grid-template-areas:
    '.'
    'header'
    'subHeader'
    '.'
    'description'
    'button'
    '.';
`

export default Wrapper

import styled from 'styled-components'
import { rgba } from 'polished'

import { media } from '~styles/mediaQuery'
import { transitions, zIndex } from '~styles/constants'

export default styled.nav`
  text-align: center;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: ${zIndex.navBar};
  ${media.mq1`
    backdrop-filter: blur(10px);
    opacity: ${({ isActive }) => (isActive ? '1' : '0')};
    pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
    transition: opacity ${transitions.duration.short}ms ${
    transitions.easing.easeInOut
  };
    padding: 15vh 0;
    background: ${({ theme }) => rgba(theme.palette.secondary.main, 0.95)};
    @supports (backdrop-filter: blur(10px)) {
      background: ${({ theme }) => rgba(theme.palette.secondary.main, 0.85)};
    }
    height: 100vh;
    justify-content: center;
    grid-template-columns: max-content;
    grid-template-rows: 2fr repeat(6, 1fr) 2fr;
    grid-template-areas:
      'logo'
      'link-0'
      'link-1'
      'link-2'
      'link-3'
      'link-4'
      'link-5';
  `};
  ${media.mq234`
    justify-items: start;
    grid-template-columns: 1fr repeat(6, auto);
    grid-template-rows: ${(props) => `repeat(2, ${props.theme.spacing[64]})`};
    grid-template-areas:
      'logo link-0 link-1 link-2 link-3 link-4 link-5'
      'logo . . . . . .';
    padding: 0 ${({ theme }) => theme.spacing[16]};

    &::after {
      backdrop-filter: blur(10px);
      position: absolute;
      top: 0;
      content: '';
      height: 50%;
      width: 100%;
      background: ${({ theme }) =>
        `linear-gradient(to right, ${theme.palette.primary.main} 150px, ${rgba(
          theme.palette.primary.main,
          0.58,
        )} 80%)`};
      @supports (backdrop-filter: blur(10px)) {
        background: ${({ theme }) =>
          `linear-gradient(to right, ${
            theme.palette.primary.main
          } 150px, ${rgba(theme.palette.primary.main, 0.4)} 60%)`};
      }
    }
  `};
`

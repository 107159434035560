import React, { createElement } from 'react'
import PropTypes from 'prop-types'

import Wrapper from './Wrapper'
import StyledLink from './StyledLink'
import StyledButton from './StyledButton'

const Button = ({ children, to, align, className, ...props }) => (
  <Wrapper align={align} className={className}>
    {createElement(to ? StyledLink : StyledButton, { to, ...props }, children)}
  </Wrapper>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  align: PropTypes.oneOf(['center', 'right', 'left']),
  className: PropTypes.string,
}

export default Button

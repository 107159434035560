import styled from 'styled-components'

const StyledVideo = styled.video`
  width: 100%;
  height: 101vh;
  margin-top: -0.5vh;
  object-fit: cover;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
`

export default StyledVideo

import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { alpha, transitions } from '~styles/constants'
import Typography from '~components/Typography'

const StyledText = styled(Typography)`
  padding: ${(props) => props.theme.spacing[8]};
  margin: 0 ${(props) => props.theme.spacing[8]};
  color: ${({ theme }) => theme.palette.common.white};
  position: relative;
  white-space: nowrap;
  ${media.mq1`
    margin-top: ${(props) => props.theme.spacing[8]};
  `};
  ${media.mq2`
    margin: 0 ${(props) => props.theme.spacing[4]};
  `};

  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 16%;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    ${media.mq1`
      background: ${({ theme }) => theme.palette.primary.main};
    `};
    ${media.mq234`
      background: ${({ theme }) => theme.palette.secondary.main};
    `};
  }

  &:hover {
    opacity: ${({ isActive }) => (isActive ? 1 : alpha.secondary)};
    transition: ${`opacity ${transitions.duration.short}ms ${transitions.easing.easeInOut}`};
  }

  /* &:active, */
  &:hover::after {
    opacity: ${({ isActive }) => (isActive ? 1 : alpha.secondary)};
    transition: ${`opacity ${transitions.duration.short}ms ${transitions.easing.easeInOut}`};
  }
`

export default StyledText

/**
 *
 * Hamburger
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

import Wrapper from './Wrapper'
import HamburgerBox from './HamburgerBox'
import HamburgerInner from './HamburgerInner'

const Hamburger = ({ isActive, toggleIsActive }) => (
  <Wrapper onClick={toggleIsActive}>
    <HamburgerBox>
      <HamburgerInner isActive={isActive} />
    </HamburgerBox>
  </Wrapper>
)

Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleIsActive: PropTypes.func.isRequired,
}

export default Hamburger

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { NAVIGATION } from '~utils/constants'
import Header from '~components/Header'

import messages from './messages'
import Wrapper from './Wrapper'
import TextHeader from './TextHeader'
import TextSubHeader from './TextSubHeader'
import TextDescription from './TextDescription'
import StyledVideo from './StyledVideo'
import StyledButton from './StyledButton'

const PageNotFound = ({ location }) => (
  <>
    <Helmet>
      <title>404 - Page Not Found | Tania Ferreira</title>
      <meta name="description" content="Page Not Found" />
    </Helmet>
    <Header pathname={location.pathname} />
    <Wrapper>
      <TextHeader as="h1" type="header1" uppercase>
        {messages.header}
      </TextHeader>
      <TextSubHeader as="h2" type="header1" uppercase>
        {messages.subHeader}
      </TextSubHeader>
      <TextDescription type="header3">
        {messages.callToActionText}
      </TextDescription>
      <StyledButton to={NAVIGATION.HOME.HOME}>
        {messages.callToActionButton}
      </StyledButton>
      <StyledVideo
        loop
        autoPlay
        muted
        poster="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMjI0IDEwODAiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM3MjkwYTYiIGQ9Ik0wIDBoMjIyNHYxMDc3SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0LjMgNC4zKSBzY2FsZSg4LjY4NzUpIiBmaWxsLW9wYWNpdHk9Ii41Ij48ZWxsaXBzZSBmaWxsPSIjZGVlMWZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC04MC40IDY0LjggLTc2LjYpIHNjYWxlKDMzLjM5OTk0IDEyMC44MDQpIi8+PGVsbGlwc2UgZmlsbD0iIzEwMTAyZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg5MS4zNjg4MiA0LjE5MTA0IC0uNzA2OTEgMTUuNDExMzUgNDUuOCAzOSkiLz48ZWxsaXBzZSBmaWxsPSIjMWYwMTAwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDQ1LjYyMjc4IC0xNS4xODk5OSAzLjM1ODE4IDEwLjA4NjIgMjU1IDM1LjIpIi8+PHBhdGggZmlsbD0iIzg0YmRjZSIgZD0iTTEyNSAxMDhsMTI1LTU1SDYxeiIvPjxlbGxpcHNlIGZpbGw9IiMzZTQzNjUiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMy4zNTk2MyAtMTYuMzY2ODQgNDQuODUxIDkuMjA2NTkgNDEgMjMuOCkiLz48ZWxsaXBzZSBmaWxsPSIjZmZmZGZmIiBjeD0iMTIyIiBjeT0iMjEiIHJ4PSIyOSIgcnk9IjkiLz48ZWxsaXBzZSBmaWxsPSIjM2Y4MjkxIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDE0NC4zNjk2IC0yOS41NzYxNyA3LjcxMTM0IDM3LjY0MTIyIDIyOSAxMjMpIi8+PGVsbGlwc2UgZmlsbD0iIzM5M2Q0NiIgY3g9IjE1MiIgY3k9IjQ4IiByeD0iMjU1IiByeT0iNSIvPjwvZz48L3N2Zz4="
      >
        <source
          src="https://res.cloudinary.com/taniaferreira/video/upload/e_boomerang/PageNotFound/Swim_LakeSilvaplana_01.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </StyledVideo>
    </Wrapper>
  </>
)

PageNotFound.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PageNotFound

import styled from 'styled-components'

import { Link } from 'gatsby'

const NavLink = styled(Link)`
  grid-area: ${({ position }) => `link-${position}`};
  position: relative;
  z-index: 1;
`

export default NavLink

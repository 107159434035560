import styled from 'styled-components'

import { transitions } from '~styles/constants'

const HamburgerInner = styled.div`
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 100%;
  height: 5px;
  background: ${props => props.theme.palette.primary.main};
  border-radius: 4px;
  position: absolute;
  transform: ${({ isActive }) => isActive && 'rotate(225deg)'};
  transition: ${({ isActive }) =>
    isActive
      ? 'transform 220ms cubic-bezier(0.215, 0.61, 0.355, 1) 120ms'
      : 'transform 220ms cubic-bezier(0.55, 0.055, 0.675, 0.19)'};

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background: ${props => props.theme.palette.primary.main};
    border-radius: 4px;
    position: absolute;
    transition: ${`transform ${transitions.duration.short}ms ${transitions.easing.easeInOut}`};
  }

  &::before {
    top: ${props => (props.isActive ? '0' : '-10px')};
    opacity: ${props => props.isActive && '0'};
    transition: ${props =>
      props.isActive
        ? 'top 100ms ease-out, opacity 100ms ease-out 120ms'
        : 'top 100ms ease-in 250ms, opacity 100ms ease-in'};
  }
  &::after {
    bottom: ${props => (props.isActive ? '0' : '-10px')};
    transform: ${props => props.isActive && 'rotate(-90deg)'};
    transition: ${props =>
      props.isActive
        ? 'bottom 100ms ease-out, transform 220ms cubic-bezier(0.215, 0.61, 0.355, 1) 120ms'
        : 'bottom 100ms ease-in 250ms, transform 220ms cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
  }
`

export default HamburgerInner

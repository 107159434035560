import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { alpha, transitions, zIndex } from '~styles/constants'

const Wrapper = styled.div`
  ${media.mq234`
    display: none
  `};
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: ${zIndex.navBar + 1};
  padding: 15px;
  display: inline-block;
  cursor: pointer;
  transition: ${`opacity ${transitions.duration.short}ms ${transitions.easing.easeInOut}`};
  font: inherit;
  /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    opacity: ${alpha.primary};
  }
`

export default Wrapper

import styled from 'styled-components'
import { Link } from 'gatsby'

import stylingButton from './stylingButton'

const StyledLink = styled(Link)`
  ${stylingButton};
`

export default StyledLink

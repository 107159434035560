const messages = {
  header: 'error 404',
  subHeader:
    'Ach je. Es sieht so aus, als ob die gesuchte Seite entspannt über dem Silvaplanerss am Schwimmen ist',
  callToActionText:
    'Verwende die Navigation oben oder gelangen hier zurück zur Startseite.',
  callToActionButton: 'Startseite',
}

export default messages

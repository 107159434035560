import styled from 'styled-components'

import Button from '~components/Button'

const StyledButton = styled(Button)`
  grid-area: button;
  position: relative;
  z-index: 1;
`

export default StyledButton

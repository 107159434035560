import { css } from 'styled-components'

import { mapThemePropsToCss } from '~styles/helpers'
import { shadows, transitions } from '~styles/constants'

const stylingButton = css`
  filter: ${({ disabled }) => (disabled ? 'saturate(0.3) blur(1.2px)' : '')};
  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: ${`filter ${transitions.duration.short}ms ${transitions.easing.easeInOut}, box-shadow ${transitions.duration.shorter}ms ${transitions.easing.easeIn}`};
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.spacing[2]};
  border: ${({ theme }) =>
    `${theme.spacing[2]} solid ${theme.palette.secondary.main}`};
  padding: ${(props) => `${props.theme.spacing[4]} ${props.theme.spacing[16]}`};
  ${({ theme, font = 'body2' }) => mapThemePropsToCss({ type: font, theme })};
  cursor: pointer;
  box-shadow: ${shadows[1]};
  color: ${({ theme }) => theme.palette.primary.main};
  display: inline-flex;

  &:hover {
    box-shadow: ${shadows[2]};
  }
`

export default stylingButton

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { NAVIGATION } from '~utils/constants'
import { setBodyScroll } from '~styles/helpers'
import { breakpoint } from '~styles/mediaQuery'
import useWindowSize from '~hooks/useWindowSize'

import StyledText from './StyledText'
import NavBar from './NavBar'
import NavLink from './NavLink'
import NavLogo from './NavLogo'
import Wrapper from './Wrapper'
import Hamburger from './Hamburger'
import IconTaniaFerreira from './IconTaniaFerreira'

const NavigationItems = [
  { link: NAVIGATION.HOME.HOME, name: 'home' },
  { link: NAVIGATION.ABOUT.ABOUT, name: 'about' },
  { link: NAVIGATION.SERVICE.SERVICE, name: 'service' },
  { link: NAVIGATION.PRICE.PRICE, name: 'price' },
  { link: NAVIGATION.BLOG.BLOG, name: 'blog' },
  { link: NAVIGATION.CONTACT.CONTACT, name: 'contact' },
]

const messages = {
  home: 'Home',
  about: 'Über mich',
  service: 'Angebot',
  price: 'Preise',
  blog: 'Blog',
  contact: 'Kontakt',
}

const Header = ({ pathname }) => {
  const [isActive, setIsActive] = useState(false)
  const { innerWidth: windowWidth } = useWindowSize()

  useEffect(() => {
    if (isActive && windowWidth >= breakpoint.breakpointSmall) {
      setIsActive(!isActive)
      setBodyScroll(true)
    }
  }, [windowWidth, isActive])

  const handleClick = () => {
    if (windowWidth < breakpoint.breakpointSmall) {
      setIsActive(!isActive)
      setBodyScroll(isActive)
    }
  }

  return (
    <Wrapper>
      <Hamburger isActive={isActive} toggleIsActive={handleClick} />
      <NavBar isActive={isActive} onClick={handleClick}>
        <NavLogo to="/" role="button">
          <IconTaniaFerreira
            height={116}
            width={116}
            isActive={pathname === NAVIGATION.HOME.HOME}
          />
        </NavLogo>
        {NavigationItems.map((item, index) => (
          <NavLink key={item.name} to={item.link} position={index}>
            <StyledText isActive={pathname === item.link}>
              {messages[item.name]}
            </StyledText>
          </NavLink>
        ))}
      </NavBar>
    </Wrapper>
  )
}

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default Header
